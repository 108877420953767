export default function convertRouteToSlug(route) {
    if (route.path === "/" || (route.path === "/" && Object.keys(route.query).length > 0)) {
        return "/home";
    }

    if (route.params.page && Array.isArray(route.params.page)) {
        return `/${route.params.page.filter(Boolean).join("/")}`;
    }

    return route.path.startsWith("/") ? route.path : `/${route.path}`;
}